<template>
    <section>
        <modal ref="modalMapaCedis" titulo="Ubicación de cedis" :height="60" :fix-height="60" tamano="modal-xl" icon="location" no-aceptar no-cancelar :btns="btns" :cargando="loading">
            <div style="height:calc(100% - 1px)">
                <mapa-google ref="GoogleMaps" height="100%" />
            </div>
        </modal>
    </section>
</template>
<script>

import cedisService from '~/services/cedis/index'



export default {
    data(){
        return{
            loading: false,
            btns: [{texto: 'Cerrar', color: 'bg-lightf5 text-general2 br-10'}]
        }
    },
    
    methods: {
        async toggle(idMoneda, moneda){
            await this.getCedis(idMoneda, moneda)
            this.$refs.modalMapaCedis.toggle();
        },
        async getCedis(idMoneda, moneda){
            try {
                this.loading = true
                const params = {
                    id_moneda: idMoneda,
                }

                const contentString = (el) => {
                    const contentString = `
                        <div class="container-fluid px-0">
                            <div class="row mx-0">
                                <div class="col-auto" style="border-radius:12px;text-align:center;">
                                    <img src="${el.imagen}" width="200px" height="200px" style="border-radius:12px;object-fit:cover;" />
                                </div>
                                <div class="col px-0">
                                    <div class="row mx-0 mb-3">
                                        <div class="col-12 px-0">
                                            <p class="px-2 text-general f-18 f-600 nombre-cedis" >
                                                ${el.nombre}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-middle mx-0">
                                        <span class="icon-location f-25 text-gr-general"></span>
                                        <div class="col px-0 nombre-direccion">
                                            <p class="pl-0 text-general f-16">
                                                ${el.direccion}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-middle mx-0">
                                        <span class="icon-package-variant-closed f-25 text-gr-general"></span>
                                        <p class="tres-puntos text-general f-16 my-2">
                                            ${this.agregarSeparadoresNumero(el.productos)} Productos
                                        </p>
                                    </div>
                                    <div class="d-middle d-flex mx-0">
                                        <span class="icon-format-list-checks f-25 text-gr-general"></span>
                                        <p class="pl-0 tres-puntos text-general f-16 my-2">
                                            ${this.agregarSeparadoresNumero(el.unidades)} Unidades
                                        </p>
                                    </div>
                                    <div class="d-middle d-flex mx-0">
                                        <span class="icon-currency-usd-circle f-25 text-gr-general"></span>
                                        <p class="pl-0 tres-puntos text-general f-16 my-2">
                                            ${this.separadorNumero(el.valor)} ${moneda.sigla}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;

                    return { lat: el.latitud, lng: el.longitud, icon: '/img/icons/cedis-point-map.svg', info: contentString}
                }

                const { data } = await cedisService.get_cedis(params);
                const cedisFormat = data.cedis.map(contentString)
                //muestra tooltip si la resolución de la pantalla es mayor 680
                let esMayorA680 = window.innerHeight >= 680
                this.$refs.GoogleMaps.initMapMarkerClusterer(cedisFormat, false, esMayorA680 )
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.nombre-direccion{
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
